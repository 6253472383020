/**
** JAVASCRIPTS
** Name: Keys
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-keys').length > 0) {

    $('.js-keys').each(function() {
      var $keys   = $(this),
          $slider = $keys.find('.js-keys-items'),
          $prev   = $keys.find('.js-keys-prev'),
          $next   = $keys.find('.js-keys-next');

      $slider.slick({
        autoplay: false,
        speed: 500,
        infinite: false,
        fade: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 959,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });

      // PREV
      $prev.on('click', function() {
        $slider.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $slider.slick('slickNext');
      });

      // ON CHANGE
      $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        if(nextSlide == 0) {
          $prev.addClass('is-disabled');
          $next.removeClass('is-disabled');
        }
        else {
          $prev.removeClass('is-disabled');

          if(nextSlide == (slick.slideCount - slick.options.slidesToShow)) {
            $next.addClass('is-disabled');
          }
          else {
            $next.removeClass('is-disabled');
          }
        }
      });

    });

  }

})();
