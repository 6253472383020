/**
** JAVASCRIPTS
** Name: Toggle
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-toggle').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $toggles = document.querySelectorAll('.js-toggle');


    /**
    ** Events
    ****************************************/

    $toggles.forEach(function($toggle) {
      const $trigger = $toggle.querySelector('.js-toggle-trigger');

      $trigger.addEventListener('click', (event) => {
        $toggle.classList.toggle('is-active');
      });
    });


  }

})();
