/**
** JAVASCRIPTS
** Name: Animate
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import AOS from 'aos';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('[data-aos]').length > 0) {
    AOS.init({
      offset: 400,
      duration: 600,
      startEvent: 'load'
    });
  }

  $(window).on('load', AOS.refresh);
  $(window).on('resize', AOS.refresh);

})();
